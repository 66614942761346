import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import Button from '@appchoose/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Input from '@appchoose/input';
import { toast } from '@appchoose/toast';

import { categoriesState, templatesState } from '../../stores/templates';
import {
  CategoryData,
  TemplateData,
  useCreateTemplateCategoryMutation,
  useTemplatesLazyQuery,
} from '../../types/generated';

type NewTemplateFolderProps = {
  setIsOpen: (isOpen: boolean) => void;
};

type NewTemplateFolderForm = {
  name: string;
};

export const NewTemplateFolder = ({ setIsOpen }: NewTemplateFolderProps) => {
  const { t } = useTranslation();

  const form = useForm<NewTemplateFolderForm>({
    mode: 'onTouched',
  });

  const [templates, { data: templatesData, error: templatesError }] =
    useTemplatesLazyQuery();

  const [createTemplateCategory, { error: createTemplateCategoryError }] =
    useCreateTemplateCategoryMutation();

  const setCategories = useSetRecoilState(categoriesState);
  const setTemplates = useSetRecoilState(templatesState);

  useEffect(() => {
    if (templatesData) {
      setTemplates(
        templatesData?.templates?.templates?.filter(
          (template): template is TemplateData => !!template
        )
      );
      setCategories(
        templatesData?.templates?.categories?.filter(
          (category): category is CategoryData => !!category
        )
      );
      setIsOpen(false);
    }
  }, [templatesData]);

  useEffect(() => {
    if (templatesError) {
      toast.error(
        'Une erreur est survenue en récupérant la liste des templates'
      );
    }
  }, [templatesError]);

  useEffect(() => {
    if (createTemplateCategoryError) {
      toast.error('Une erreur est survenue en créant un nouveau dossier');
    }
  }, [createTemplateCategoryError]);

  const onSubmit = async (data: NewTemplateFolderForm) => {
    try {
      await createTemplateCategory({
        variables: {
          name: data.name,
        },
      });

      templates();
    } catch {
      // Something went wrong
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="overflow-y-auto p-6 md:p-10">
          <h3
            id="modal-new-template-folder-title"
            className="mb-6 text-2xl font-bold text-gray-900"
          >
            {t('templates.template_category.new_folder')}
          </h3>
          <div className="pb-4">
            <FormField
              control={form.control}
              name="name"
              rules={{
                required: true,
                maxLength: 200,
              }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('templates.template_category.fields.name.label')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t(
                        'templates.template_category.fields.name.placeholder'
                      )}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage match="required">
                    {t(
                      'templates.template_category.fields.name.validation_errors.required'
                    )}
                  </FormMessage>
                  <FormMessage match="maxLength">
                    {t(
                      'templates.template_category.fields.name.validation_errors.maxLength',
                      {
                        maxLength: '200',
                      }
                    )}
                  </FormMessage>
                </FormItem>
              )}
            />
          </div>
        </div>
        <footer className="flex shrink-0 justify-end space-x-6 border-t border-gray-100 p-6 md:p-10 md:pt-6">
          <Button appearance="primary" type="submit">
            {t('templates.template_category.create')}
          </Button>
        </footer>
      </form>
    </Form>
  );
};
