import React from 'react';
import { useTranslation } from 'react-i18next';

import { MessageFromSearchResult } from '../../stores/searchResults';
import { transformDateWithYear } from '../../utils/date';
import { ElasticSearchHighlights } from '../../utils/elastic-search';

const getHighlightedValueFromKey = (
  highlights: ElasticSearchHighlights,
  key: string,
  fallback = ''
) => {
  if (highlights?.[key]) {
    return highlights[key].value;
  }
  return fallback;
};

type ConversationSummarySearchResultProps = {
  message: MessageFromSearchResult;
  selectMessage: (userKey: string, fromClick?: boolean) => void;
  selectEmail: (email: string) => void;
  activeId: string;
};

export const ConversationSummarySearchResult: React.FC<
  ConversationSummarySearchResultProps
> = ({
  message,
  selectMessage,
  selectEmail,
  activeId,
}: ConversationSummarySearchResultProps) => {
  const { i18n, t } = useTranslation();

  const orderHighlight = getHighlightedValueFromKey(
    message.highlights ?? {},
    'order_id'
  );
  const nameHighlight = getHighlightedValueFromKey(
    message.highlights ?? {},
    'name',
    message.name ?? ''
  );
  const emailHighlight = getHighlightedValueFromKey(
    message.highlights ?? {},
    'email',
    message.email ?? ''
  );

  const selectConversation = () => {
    if (message.userKey) selectMessage(message.userKey ?? '', true);
    else selectEmail(message.email ?? '');
  };

  const isActive = () => {
    return message.userKey
      ? activeId === message.userKey
      : activeId === message.email;
  };

  return (
    <li
      onClick={selectConversation}
      className={`cursor-pointer p-4 hover:bg-gray-50 ${
        isActive() ? 'bg-gray-50' : ''
      }`}
    >
      <div className="space-y-2">
        <h5 className="truncate text-sm text-gray-600">
          <span
            dangerouslySetInnerHTML={{
              __html: nameHighlight,
            }}
          />
        </h5>
        {orderHighlight ? (
          <p
            className="text-xs text-gray-600"
            dangerouslySetInnerHTML={{ __html: orderHighlight }}
          />
        ) : null}
        {emailHighlight ? (
          <p
            className="text-xs text-gray-600"
            dangerouslySetInnerHTML={{ __html: emailHighlight }}
          />
        ) : null}
        <p className="pb-3 text-xs text-gray-600">
          {orderHighlight
            ? t('list.message.dated')
            : t('list.message.last_activity_date')}
          {message.lastTime
            ? transformDateWithYear(
                new Date(message.lastTime),
                i18n.language === 'fr' ? 'fr' : 'en'
              )
            : ''}
        </p>
      </div>
    </li>
  );
};

ConversationSummarySearchResult.displayName = 'ConversationSummarySearchResult';
