import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@appchoose/form';
import Label from '@appchoose/label';
import {
  ModalClose,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@appchoose/modal';
import RadioGroup, { RadioGroupItem } from '@appchoose/radio-group';
import { toast } from '@appchoose/toast';

import {
  useCreateReturnMutation,
  useJoinReturnSlipMutation,
} from '../../types/generated';
import { Order } from '../../types/order';
import { DeliveryServiceFormData } from '../other-actions-modal/delivery-service-form-fields';
import { TrackingNumberFormData } from '../other-actions-modal/tracking-number-form-fields';
import {
  CreateReturnSlipProductsForm,
  CreateReturnSlipStepProducts,
} from './create-return-slip-step-products';
import { CreateReturnSlipStepProductsWithoutReason } from './create-return-slip-step-products-without-reason';
import { CreateReturnSlipStepTracking } from './create-return-slip-step-tracking';

type CreateReturnSlipModalProps = {
  setIsOpen: (isOpen: boolean) => void;
  order?: Order;
  userKey: string | undefined;
};

export type CreateReturnSlipModalForm = {
  type: string;
} & CreateReturnSlipProductsForm &
  TrackingNumberFormData &
  DeliveryServiceFormData;

export const CreateReturnSlipModal = ({
  setIsOpen,
  order,
  userKey,
}: CreateReturnSlipModalProps) => {
  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  const [nbSteps, setNbSteps] = useState(2);

  const form = useForm<CreateReturnSlipModalForm>({
    mode: 'onTouched',
    defaultValues: {
      products:
        order?.items?.map((product) => ({
          product: product,
          selected: false,
        })) ?? [],
    },
  });

  const [
    createReturnMutation,
    { loading: createReturnLoading, error: createReturnError },
  ] = useCreateReturnMutation();
  const [
    joinReturnSlipMutation,
    { loading: joinReturnSlipLoading, error: joinReturnSlipError },
  ] = useJoinReturnSlipMutation();

  useEffect(() => {
    if (createReturnError) {
      switch (createReturnError.message) {
        case 'missing_zip_code_return_address_seller':
          toast.error(
            "L'adresse de retour de la marque est incomplète. Il manque le code postal. Vous devez le modifier dans les réglages du dashboard de la marque."
          );
          break;
        case 'missing_name_return_address_seller':
          toast.error(
            "L'adresse de retour de la marque est incomplète. Il manque le nom/raison social. Vous devez le modifier dans les réglages du dashboard de la marque."
          );
          break;
        case 'invalid_destination_country_return_address_seller':
          toast.error(
            "L'adresse de retour de la marque semble incorrecte. Vérifiez dans les réglages du dashboard de la marque l'adresse de retour (Pays, code postal,..)"
          );
          break;
        default:
          toast.error(
            "Une erreur est survenue en créant le bon de retour. Vérifiez l'addresse de retour de la marque dans les réglages du dashboard et de livraison du client. RAPPEL : les retours de clients hors France vers hors France ne fonctionnent pas"
          );
      }
    }
  }, [createReturnError]);

  useEffect(() => {
    if (joinReturnSlipError) {
      if (
        joinReturnSlipError.message ===
        'join_order_return_slip_a_return_slip_already_exist'
      ) {
        toast.error(t('return.return.errors.return_slip_already_exists'));
      } else if (
        joinReturnSlipError.message === 'no_courier_detect_to_tracking_number'
      ) {
        toast.error(
          t('return.return.errors.no_courier_detect_to_tracking_number')
        );
      } else if (
        joinReturnSlipError.message === 'join_order_return_slip_item_not_found'
      ) {
        toast.error(t('return.return.errors.item_not_found'));
      } else if (joinReturnSlipError.message === 'Tracking already exists.') {
        toast.error(t('return.return.errors.tracking_already_exists'));
      } else {
        toast.error(t('return.return.errors.join_return_slip_error'));
      }
    }
  }, [joinReturnSlipError]);

  const createTypeOptions = [
    {
      value: 'to_choose',
      name: t('return.return.types.to_choose'),
    },
    {
      value: 'to_the_brand',
      name: t('return.return.types.to_the_brand'),
    },
  ];

  const attachTypeOptions = [
    {
      value: 'attach_return_slip',
      name: t('return.return.types.attach_return_slip'),
    },
  ];

  const goToNextOrConfirm = async (data: CreateReturnSlipModalForm) => {
    if (step < nbSteps - 1) {
      setStep((prevStep) => prevStep + 1);
      return;
    }

    try {
      if (data.type === 'attach_return_slip') {
        await joinReturnSlipMutation({
          variables: {
            orderId: order?.id ?? '',
            input: {
              itemReturnReasonCodes: data.products
                .filter((product) => product.selected)
                .map((product) => ({
                  itemId: product.product.id,
                  returnReasonCode: product.reason,
                })),
              trackingCarrierSlug: data.deliveryService,
              trackingNumber: data.trackingNumber.trim().replace(/\s/g, ''),
            },
          },
        });

        toast.success(t('return.return.success'));

        setIsOpen(false);
      } else {
        await createReturnMutation({
          variables: {
            orderId: order?.id ?? '',
            userKey: userKey as string,
            deliveryToChoose: data.type === 'to_choose',
            products: data.products
              .filter((product) => product.selected)
              .map((product) => product.product)
              .map((product) => ({
                id: product.productId,
                variant_id: product.productVariantId,
                size: product.size,
                quantity: 1,
              })),
          },
        });

        toast.success(t('return.return.success'));

        setIsOpen(false);
      }
    } catch {
      // Something went wrong
    }
  };

  const onSubmit = async (data: CreateReturnSlipModalForm) => {
    switch (step) {
      case 0:
        setStep((prevStep) => prevStep + 1);
        if (data.type === 'attach_return_slip') setNbSteps(3);
        break;
      case 1:
      case 2:
        await goToNextOrConfirm(data);
        break;
    }
  };

  const TypeErrorMessage =
    form.formState.errors.type?.type === 'required' &&
    `${t('return.return.fields.type.validation_errors.required')}`;

  const canCreateReturnSlip =
    order?.seller?.returnAddress?.countryCode === 'FR' ||
    order?.recipient.address.countryCode === 'FR';

  let title = t('return.return.create_or_attach_return_slip');
  if (step > 0) {
    if (
      createTypeOptions.find((option) => option.value === form.getValues().type)
    ) {
      title = t('return.return.create_return_slip');
    }
    if (
      attachTypeOptions.find((option) => option.value === form.getValues().type)
    ) {
      title = t('return.return.attach_return_slip');
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex h-full flex-col justify-between overflow-hidden"
      >
        <div className="flex flex-auto flex-col overflow-y-auto p-6 md:p-10">
          <ModalHeader className="mb-6">
            <ModalTitle>{title}</ModalTitle>
          </ModalHeader>

          {step === 0 && (
            <div className="space-y-6">
              <div className="space-y-4">
                <div className="font-semibold text-gray-700">
                  {t('return.return.create_return_slip')}
                </div>
                <FormField
                  control={form.control}
                  name="type"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="sr-only" hidden>
                        {t('return.return.fields.type.label')}
                      </FormLabel>
                      <FormControl>
                        <RadioGroup
                          value={field.value}
                          onValueChange={(value) => field.onChange(value)}
                          disabled={!canCreateReturnSlip}
                          className="gap-4"
                        >
                          {createTypeOptions.map((option) => (
                            <div
                              className="group flex items-center space-x-3"
                              key={option.value}
                            >
                              <RadioGroupItem
                                id={`attach-option-${option.value}`}
                                key={option.value}
                                value={option.value}
                              />
                              <Label
                                htmlFor={`attach-option-${option.value}`}
                                className="cursor-pointer text-sm text-gray-700 group-has-[.peer:disabled]:cursor-default group-has-[.peer:disabled]:text-gray-300 group-has-[.peer[data-state=checked]]:text-gray-900"
                              >
                                {option.name}
                              </Label>
                            </div>
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>

              <div className="flex items-center space-x-2 text-gray-300">
                <span className="h-[0.06px] w-6 bg-gray-300"></span>
                <span className="text-xs uppercase">
                  {t('return.return.or')}
                </span>
                <span className="h-[0.06px] w-6 bg-gray-300"></span>
              </div>

              <div className="space-y-4">
                <div className="font-semibold text-gray-700">
                  {t('return.return.attach_return_slip')}
                </div>
                <FormField
                  control={form.control}
                  name="type"
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <FormItem>
                      <FormLabel className="sr-only" hidden>
                        {t('return.return.fields.type.label')}
                      </FormLabel>
                      <FormControl>
                        <RadioGroup
                          value={value}
                          onValueChange={(value) => onChange(value)}
                          className="gap-4"
                        >
                          {attachTypeOptions.map((option) => (
                            <div
                              className="group flex items-center space-x-3"
                              key={option.value}
                            >
                              <RadioGroupItem
                                id={`attach-option-${option.value}`}
                                key={option.value}
                                value={option.value}
                              />
                              <Label
                                htmlFor={`attach-option-${option.value}`}
                                className="cursor-pointer text-sm text-gray-700 group-has-[.peer:disabled]:cursor-default group-has-[.peer:disabled]:text-gray-300 group-has-[.peer[data-state=checked]]:text-gray-900"
                              >
                                {option.name}
                              </Label>
                            </div>
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
              {TypeErrorMessage && (
                <p className="text-xs text-red-600">{TypeErrorMessage}</p>
              )}
            </div>
          )}
          {step === 1 ? (
            form.getValues('type') === 'attach_return_slip' ? (
              <CreateReturnSlipStepProducts />
            ) : (
              <CreateReturnSlipStepProductsWithoutReason />
            )
          ) : null}
          {step === 2 ? (
            <CreateReturnSlipStepTracking orderId={order?.id ?? ''} />
          ) : null}
        </div>

        <ModalFooter>
          {step === 0 ? (
            <ModalClose className="text-sm font-bold text-green-900">
              {t('cancel')}
            </ModalClose>
          ) : null}
          {step > 0 ? (
            <button
              type="button"
              className="text-sm font-bold text-green-900"
              onClick={() => setStep((prevStep) => prevStep - 1)}
            >
              {t('previous')}
            </button>
          ) : null}
          <Button
            appearance="primary"
            type="submit"
            disabled={createReturnLoading || joinReturnSlipLoading}
          >
            {step === nbSteps - 1 ? (
              <span>{t('return.return.confirm')}</span>
            ) : (
              <span>{t('return.return.next')}</span>
            )}
          </Button>
        </ModalFooter>
      </form>
    </Form>
  );
};

CreateReturnSlipModal.displayName = 'CreateReturnSlipModal';
