import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import Button from '@appchoose/button';
import Icon from '@appchoose/icon';
import Loader from '@appchoose/loader';
import { Popover, PopoverContent, PopoverTrigger } from '@appchoose/popover';
import { toast } from '@appchoose/toast';
import * as Dialog from '@radix-ui/react-dialog';

import { uploadFile } from '../../api/firebase';
import { activeOperatorsState } from '../../stores/activeOperators';
import { imageViewerState } from '../../stores/imageViewerState';
import { isProcessingState } from '../../stores/isProcessing';
import { mobileUserSidebarOpenState } from '../../stores/mobileUserSidebarOpenState';
import { operatorsState } from '../../stores/operators';
import {
  selectedIsFromEmailState,
  selectedLastMessageState,
  selectedState,
  selectedThreadMessagesState,
} from '../../stores/selected';
import {
  firstEmailFromSelectedEmailState,
  fixedEmailsFromSelectedEmailState,
  pinnedEmailsFromSelectedEmailState,
  selectedEmailState,
} from '../../stores/selectedEmail';
import { useUser } from '../../stores/usercontext';
import {
  ConversationCategory,
  ConversationType,
  DeliveryType,
  MessageStatus,
  OperatorUser,
  StoreRegion,
  TemplateData,
  useAssignConversationMutation,
  usePushMessageMutation,
  useSetConversationAsReadMutation,
  useSetConversationCategoryMutation,
} from '../../types/generated';
import {
  convertDateToZonedTime,
  transformDateLastTime,
} from '../../utils/date';
import { CategorySelect } from '../category-select/category-select';
import { DeliverySelect } from '../delivery-select/delivery-select';
import { EmojiPopoverContent } from '../emoji-popover-content/emoji-popover-content';
import { FilesPreview } from '../files-preview/files-preview';
import { ImageGallery } from '../image-gallery/image-gallery';
import { OperatorSelect } from '../operator-select/operator-select';
import {
  OperatorState,
  OperatorsStatus,
} from '../operators-status/operators-status';
import { SendSelect } from '../send-select/send-select';
import { SimpleTextArea } from '../simple-textarea/simple-textarea';
import { TemplatePopoverContent } from '../template-popover-content/template-popover-content';
import { ChatMessage } from './chat-message';
import { ChatMessageGroup } from './chat-message-group';
import { EmailMessage } from './email-message';

import './conversation-thread.scss';

type ConversationThreadProps = {
  conversationStatus: MessageStatus;
  onUnselect: () => void;
  onChangeMessage: (message: string) => void;
  onFixConversation(isFixed: boolean): void;
  onPinConversation(isPinned: boolean): void;
  onAssignConversation(isAssigned: boolean, assignedOperatorId: string): void;
  onSetConversationCategory(
    isAssigned: boolean,
    assignedCategory: ConversationCategory
  ): void;
  onSubmit: (
    message: string,
    email?: { email: string; subject: string },
    status?: MessageStatus
  ) => void;
};

export const ConversationThread: React.FC<ConversationThreadProps> = ({
  conversationStatus,
  onUnselect,
  onChangeMessage,
  // eslint-disable-next-line @typescript-eslint/unbound-method
  onFixConversation,
  // eslint-disable-next-line @typescript-eslint/unbound-method
  onPinConversation,
  // eslint-disable-next-line @typescript-eslint/unbound-method
  onAssignConversation,
  // eslint-disable-next-line @typescript-eslint/unbound-method
  onSetConversationCategory,
  onSubmit,
}: ConversationThreadProps) => {
  const { i18n, t } = useTranslation();

  const [selectedEmail, setSelectedEmail] = useRecoilState(selectedEmailState);
  const firstEmailFromSelectedEmail = useRecoilValue(
    firstEmailFromSelectedEmailState
  );
  const pinnedEmailsFromSelectedEmail = useRecoilValue(
    pinnedEmailsFromSelectedEmailState
  );
  const fixedEmailsFromSelectedEmail = useRecoilValue(
    fixedEmailsFromSelectedEmailState
  );
  const activeOperators = useRecoilValue(activeOperatorsState);
  const operators = useRecoilValue(operatorsState);
  const [isProcessing, setIsProcessing] = useRecoilState(isProcessingState);
  const setMobileUserSidebarOpen = useSetRecoilState(
    mobileUserSidebarOpenState
  );
  const [imageViewer, setImageViewer] = useRecoilState(imageViewerState);
  const [selected, setSelected] = useRecoilState(selectedState);
  const selectedLastMessage = useRecoilValue(selectedLastMessageState);
  const selectedIsFromEmail = useRecoilValue(selectedIsFromEmailState);
  const selectedThreadMessages = useRecoilValue(selectedThreadMessagesState);

  const { user: userAuthenticated } = useUser();

  const input = useRef<HTMLTextAreaElement | null>(null);
  const [draftMessage, setDraftMessage, removeDraftMessage] =
    useLocalStorage<string>(`draft-message-${selected?.user?.userKey ?? ''}`);
  const [draftSelection, setDraftSelection, removeDraftSelection] =
    useLocalStorage<number>(`draft-selection-${selected?.user?.userKey ?? ''}`);

  const [message, setMessage] = useState({
    value: '',
    selection: 0,
  });

  const [displayFix] = useState(conversationStatus === MessageStatus.Unhandled);

  const [delivery, setDelivery] = useState<'messagerie' | 'email'>(
    selectedLastMessage?.fromEmail ? 'email' : 'messagerie'
  );
  const [hasStartedWriting, setHasStartedWriting] = useState(false);

  const inputFile = useRef<HTMLInputElement | null>(null);

  const [files, setFiles] = useState<File[] | undefined>(undefined);

  const [
    pushMessageMutation,
    { error: pushMessageError, loading: pushMessageLoading },
  ] = usePushMessageMutation();
  const [setConversationAsReadMutation, { error: setConversationAsReadError }] =
    useSetConversationAsReadMutation();

  const activeOperator = operators.filter(
    (operator) => operator.operatorId === userAuthenticated?.uid || ''
  );
  const otherOperators = operators.filter(
    (user) => user?.operatorId !== userAuthenticated?.uid || ''
  );
  const users = activeOperator.concat(otherOperators);

  const [assignedUser, setAssignedUser] = useState<OperatorUser | undefined>(
    undefined
  );
  const [conversationCategory, setConversationCategory] = useState<
    ConversationCategory | undefined
  >(undefined);
  const [assignConversationMutation, { error: assignConversationError }] =
    useAssignConversationMutation();
  const [
    setConversationCategoryMutation,
    { error: setConversationCategoryError },
  ] = useSetConversationCategoryMutation();

  useEffect(() => {
    if (selected?.user) {
      if (selectedIsFromEmail) {
        setDelivery('email');
      } else {
        setDelivery('messagerie');
      }

      const textToAdd = processName('');
      setMessage({
        value: draftMessage || textToAdd,
        selection: draftSelection || textToAdd.indexOf('\n\n') + 2,
      });

      setAssignedUser(
        operators.find(
          (operator) => operator.operatorId === selected?.status?.assignTo
        )
      );
      setConversationCategory(selected?.status?.category || undefined);
    } else {
      setDelivery('messagerie');

      setMessage({
        value: '',
        selection: 0,
      });
    }
    setHasStartedWriting(false);
  }, [selected, selectedEmail]);

  useEffect(() => {
    if (pushMessageError) {
      toast.error('Une erreur est survenue en envoyant un message');
    }
  }, [pushMessageError]);

  useEffect(() => {
    if (setConversationAsReadError) {
      toast.error(
        'Une erreur est survenue en archivant ou épinglant un message'
      );
    }
  }, [setConversationAsReadError]);

  useEffect(() => {
    if (assignConversationError) {
      toast.error('Une erreur est survenue en assignant le message');
    }
  }, [assignConversationError]);

  useEffect(() => {
    if (setConversationCategoryError) {
      toast.error('Une erreur est survenue en assignant le message');
    }
  }, [setConversationCategoryError]);

  useEffect(() => {
    if (input?.current) {
      input.current.selectionStart = message.selection;
      input.current.selectionEnd = message.selection;

      const searchInput = document.getElementById('search-conversations');
      if (document.activeElement !== searchInput) {
        input.current.focus();
      }
    }
  }, [message]);

  useEffect(() => {
    window.addEventListener(
      'pushMessageConversation',
      onPushMessageConversation
    );
    return () => {
      window.removeEventListener(
        'pushMessageConversation',
        onPushMessageConversation
      );
    };
  }, []);

  const onPushMessageConversation = (event: CustomEvent<string>) => {
    setDraftMessage(event?.detail || '');
    setMessage({
      value: event?.detail || '',
      selection: 0,
    });
  };

  const onChangeTextarea = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDraftMessage(event.target.value);
    if (input.current) setDraftSelection(input.current.selectionStart);

    setMessage({
      value: event.target.value,
      selection: input.current?.selectionStart || 0,
    });
    if (hasStartedWriting && typeof onChangeMessage === 'function') {
      onChangeMessage(event.target.value);
    }
    if (!hasStartedWriting && event.target.value.length > 2) {
      setHasStartedWriting(true);
    }
  };

  const uploadFilesBeforeSendingMessage = async (): Promise<
    string[] | undefined
  > => {
    if (files) {
      const promises = files.map((file) => uploadFile(file));
      return Promise.all(promises);
    }
    return undefined;
  };

  const onSubmitEmail = async (message: string) => {
    if (
      message &&
      message !== '' &&
      selectedEmail &&
      firstEmailFromSelectedEmail &&
      !isProcessing
    ) {
      setIsProcessing(true);

      await pushMessageMutation({
        variables: {
          data: {
            userKey: firstEmailFromSelectedEmail?.email,
            body: message,
            operator: userAuthenticated?.displayName || '',
            deliveryType: DeliveryType.Email,
            email: firstEmailFromSelectedEmail?.email,
            subject: '',
            topic: undefined,
            files: undefined,
            isFixed: conversationStatus !== MessageStatus.Read ? true : false,
            isPinned:
              conversationStatus === MessageStatus.Pinned ? true : undefined,
          },
          type: ConversationType.Email,
        },
      });
    } else {
      throw new Error('Pas de message');
    }
  };

  const onSubmitMessage = async (
    message: string,
    email?: { email: string; subject: string },
    status?: MessageStatus
  ) => {
    if (
      ((message && message !== '') || (files && files.length > 0)) &&
      selected?.user?.userKey &&
      !isProcessing
    ) {
      setIsProcessing(true);

      const files = await uploadFilesBeforeSendingMessage();

      const newStatus = status || conversationStatus;

      await pushMessageMutation({
        variables: {
          data: {
            userKey: selected.user.userKey,
            body: message,
            operator: userAuthenticated?.displayName || '',
            status: newStatus,
            deliveryType: DeliveryType.App,
            email: email?.email,
            subject: email?.subject,
            topic: undefined,
            files: files,
            isFixed: newStatus !== MessageStatus.Read ? true : false,
            isPinned: newStatus === MessageStatus.Pinned ? true : undefined,
          },
          type: ConversationType.App,
        },
      });
    } else {
      throw new Error('Pas de message');
    }
  };

  const onSubmitMessageOrEmail = async (
    message: string,
    email?: { email: string; subject: string },
    status?: MessageStatus
  ) => {
    try {
      if (selected) {
        await onSubmitMessage(message, email, status);
      } else if (selectedEmail) {
        await onSubmitEmail(message);
      }

      setFiles(undefined);

      const textToAdd = processName('');
      setMessage({
        value: textToAdd,
        selection: textToAdd.indexOf('\n\n') + 2,
      });
      removeDraftMessage();
      removeDraftSelection();
      onSubmit(message, email, status);
    } catch (e) {
      toast.error((e as Error).message);
    }

    setIsProcessing(false);
  };

  const onInternalSubmit = async (event?: Event, status?: MessageStatus) => {
    if (message.value !== '') {
      if (event) {
        event.stopPropagation();
      }

      if (delivery === 'email' && selected) {
        if (
          selectedLastMessage &&
          selectedLastMessage.__typename === 'ConversationMessage'
        ) {
          const newMessage =
            message.value +
            "\n\n\n ##- SVP n'écrivez pas sous cette ligne -## \n\n\n" +
            selectedLastMessage.body +
            '\n\n\n ##:' +
            selected.user?.userKey +
            ':##';
          await onSubmitMessageOrEmail(
            newMessage,
            {
              email: selectedLastMessage.fromEmail || '',
              subject: selectedLastMessage.emailSubject || '',
            },
            status
          );
        } else {
          await onSubmitMessageOrEmail(message.value, undefined, status);
        }
      } else {
        await onSubmitMessageOrEmail(message.value, undefined, status);
      }
    }
  };

  const pinConversation = () => {
    changePinConversation(true);
  };

  const unpinConversation = () => {
    changePinConversation(false);
  };

  const fixConversation = () => {
    changeFixConversation(true);
  };

  const unfixConversation = () => {
    changeFixConversation(false);
  };

  const changeFixConversationMessage = async (isFixed: boolean) => {
    if (selected?.user?.userKey) {
      await setConversationAsReadMutation({
        variables: {
          data: {
            userKey: selected.user.userKey,
            operator: userAuthenticated?.displayName || '',
            isFixed: isFixed,
            status: conversationStatus,
          },
          type: ConversationType.App,
        },
      });
    } else {
      throw new Error('Aucune conversation à archiver');
    }
  };

  const changeFixConversationEmail = async (isFixed: boolean) => {
    if (selectedEmail) {
      await setConversationAsReadMutation({
        variables: {
          data: {
            userKey: firstEmailFromSelectedEmail?.email || '',
            operator: userAuthenticated?.displayName || '',
            isFixed: isFixed,
            status: conversationStatus,
          },
          type: ConversationType.Email,
        },
      });
    } else {
      throw new Error('Aucune conversation à archiver');
    }
  };

  const changeFixConversation = async (isFixed: boolean) => {
    setIsProcessing(true);

    if (selected) {
      try {
        await changeFixConversationMessage(isFixed);

        setSelected((prev) =>
          prev
            ? {
                ...prev,
                status: {
                  ...prev.status,
                  isFixed: isFixed,
                },
              }
            : undefined
        );

        onFixConversation(isFixed);
      } catch (e) {
        toast.error((e as Error).message);
      }
    } else if (selectedEmail) {
      try {
        await changeFixConversationEmail(isFixed);

        setSelectedEmail([
          ...selectedEmail.map((c) => ({
            ...c,
            isFixed: isFixed,
          })),
        ]);

        onFixConversation(isFixed);
      } catch (e) {
        toast.error((e as Error).message);
      }
    }

    setIsProcessing(false);
  };

  const changePinConversationMessage = async (isPinned: boolean) => {
    if (selected?.user?.userKey) {
      try {
        await setConversationAsReadMutation({
          variables: {
            data: {
              userKey: selected.user.userKey,
              operator: userAuthenticated?.displayName || '',
              isPinned: isPinned,
              status: conversationStatus,
            },
            type: ConversationType.App,
          },
        });
      } catch {
        toast.error('Une erreur est survenue');
      }
    } else {
      throw new Error('Aucune conversation à épingler');
    }
  };

  const changePinConversationEmail = async (isPinned: boolean) => {
    if (selectedEmail) {
      await setConversationAsReadMutation({
        variables: {
          data: {
            userKey: firstEmailFromSelectedEmail?.email || '',
            operator: userAuthenticated?.displayName || '',
            isPinned: isPinned,
            status: conversationStatus,
          },
          type: ConversationType.Email,
        },
      });
    } else {
      throw new Error('Aucune conversation à épingler');
    }
  };

  const changePinConversation = async (isPinned: boolean) => {
    setIsProcessing(true);

    if (selected) {
      try {
        await changePinConversationMessage(isPinned);

        setSelected((prev) =>
          prev
            ? {
                ...prev,
                status: {
                  ...prev.status,
                  isPinned: isPinned,
                },
              }
            : undefined
        );

        onPinConversation(isPinned);
      } catch (e) {
        toast.error((e as Error).message);
      }
    } else if (selectedEmail) {
      try {
        await changePinConversationEmail(isPinned);

        setSelectedEmail([
          ...selectedEmail.map((c) => ({
            ...c,
            isPinned: isPinned,
          })),
        ]);

        onPinConversation(isPinned);
      } catch (e) {
        toast.error((e as Error).message);
      }
    }

    setIsProcessing(false);
  };

  const selectSmiley = (_smiley: string) => {
    const newMessage =
      message.value.slice(0, input.current?.selectionStart) +
      _smiley +
      message.value.slice(input.current?.selectionStart, message.value.length);
    setMessage({
      value: newMessage,
      selection: (input.current?.selectionStart || 0) + _smiley.length,
    });
  };

  const processName = (message: string) => {
    const currentUserStore = selected?.user?.currentStore;
    let reply = `${t('default_messages.hi', {
      lng: currentUserStore === StoreRegion.Us ? 'en' : 'fr',
    })}\n\n`;
    if (selected?.user?.name) {
      reply = `${t('default_messages.hi', {
        lng: currentUserStore === StoreRegion.Us ? 'en' : 'fr',
      })} ${selected.user.name.split(' ')[0]},\n\n`;
    }
    reply += message;

    const hour = convertDateToZonedTime(
      new Date(),
      selected?.user?.timezone || undefined
    ).getHours();

    if (hour >= 6 && hour < 16) {
      reply += `\n\n${t('default_messages.greetings_day_message', {
        lng: currentUserStore === StoreRegion.Us ? 'en' : 'fr',
      })} ☀️`;
    } else if (hour >= 16 && hour < 18) {
      reply += `\n\n${t('default_messages.greetings_16_18_message', {
        lng: currentUserStore === StoreRegion.Us ? 'en' : 'fr',
      })} ✨`;
    } else if (hour >= 18 && hour < 22) {
      reply += `\n\n${t('default_messages.greetings_18_22_message', {
        lng: currentUserStore === StoreRegion.Us ? 'en' : 'fr',
      })} ✨`;
    } else {
      reply += `\n\n${t('default_messages.greetings_night_message', {
        lng: currentUserStore === StoreRegion.Us ? 'en' : 'fr',
      })} ✨`;
    }
    return reply;
  };

  const changeText = (category: TemplateData) => {
    const textToAdd = processName(category.message || '');
    setMessage({
      value: textToAdd,
      selection: textToAdd.length,
    });
  };

  const assignUser = async (user: OperatorUser) => {
    const isDeselection = user.operatorId === assignedUser?.operatorId;
    if (user) {
      setAssignedUser(isDeselection ? undefined : user);
      if (selected?.user?.userKey) {
        await assignConversationMutation({
          variables: {
            conversationId: selected.user.userKey,
            operatorId: isDeselection ? '' : user.operatorId,
          },
        });

        onAssignConversation(!isDeselection, user.operatorId);
      }
    }
  };

  const assignCategory = async (category: ConversationCategory) => {
    const isDeselection = category === conversationCategory;

    setConversationCategory(isDeselection ? undefined : category);
    if (selected?.user?.userKey) {
      await setConversationCategoryMutation({
        variables: {
          conversationId: selected.user.userKey,
          category: isDeselection ? null : category,
        },
      });

      onSetConversationCategory(!isDeselection, category);
    }
  };

  const handleFile = () => {
    if (inputFile) {
      inputFile.current?.click();
    }
  };

  const onUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFiles((files || []).concat([...Array.from(e.target.files)]));
      e.target.value = '';
    }
  };

  const onRemoveFile = (index: number) => {
    if (files) {
      const tmp = [...files];
      tmp.splice(index, 1);
      setFiles(tmp);
    }
  };

  const renderMessages = () => {
    const activeOperatorsLegit = activeOperators.filter(
      (operator) =>
        selected?.user?.userKey === operator.userKey &&
        operator.operatorId !== userAuthenticated?.uid
    );
    if (selected?.user)
      return (
        <>
          <ul className="conversations flex flex-col space-y-2">
            {selectedThreadMessages?.map((message) => {
              if (message.__typename === 'ConversationMessage') {
                return <ChatMessage key={message.id} message={message} />;
              }
              if (message.__typename === 'ConversationMessageGroup') {
                return <ChatMessageGroup key={message.id} message={message} />;
              }
              return null;
            })}
          </ul>
          {activeOperatorsLegit.length > 0 ? (
            <div className="sticky bottom-0 ml-auto mt-2 inline-flex">
              <OperatorsStatus
                operators={activeOperatorsLegit}
                state={OperatorState.IsReplying}
                size="medium"
                className="rounded-[2rem] bg-white p-2"
              />
            </div>
          ) : null}
        </>
      );

    if (selectedEmail)
      return (
        <ul className="conversations flex flex-col space-y-2">
          {selectedEmail.map((message, idx) => {
            return <EmailMessage key={idx} message={message} />;
          })}
        </ul>
      );

    return null;
  };

  const conversationName = () => {
    if (selectedEmail) return firstEmailFromSelectedEmail?.email;
    if (selected) return selected.user?.name ?? 'Anonyme';
    return '';
  };

  const onMoreInfoUser = () => {
    setMobileUserSidebarOpen(true);
  };

  const emitConversation = (conversation: any) => {
    const event = new CustomEvent('conversationchanged', {
      detail: conversation,
    });
    window.dispatchEvent(event);
  };

  useEffect(() => {
    emitConversation(selected);
  }, [selected]);

  return (
    <>
      <div className="message-thread relative flex h-full min-w-0 flex-initial flex-col">
        <header className="mx-6 flex items-center justify-between border-b border-gray-100 py-2.5">
          <div className="flex min-w-0 flex-initial items-center">
            <button
              type="button"
              className="mr-3 flex shrink-0 text-gray-500"
              onClick={onUnselect}
            >
              <Icon icon="arrowDown" size="large" className="rotate-90" />
            </button>
            <div className="flex min-w-0 flex-initial flex-col">
              <h5
                className="truncate text-xl font-bold text-gray-900"
                onClick={onMoreInfoUser}
              >
                {conversationName()}
              </h5>
              {selected?.user?.lastTime ? (
                <time
                  dateTime={selected.user.lastTime}
                  className="text-xs text-gray-500"
                >
                  {t('conversation.active', {
                    date: transformDateLastTime(
                      new Date(selected.user.lastTime),
                      i18n.language === 'fr' ? 'fr' : 'en'
                    ),
                  })}
                </time>
              ) : null}
            </div>
          </div>
          <div className="ml-4 flex space-x-2">
            <CategorySelect
              assignedCategory={conversationCategory}
              assignCategory={assignCategory}
            />
            <OperatorSelect
              assignedUser={assignedUser}
              users={users}
              assignUser={assignUser}
            />
            {selected?.status?.isPinned ||
            (selectedEmail && pinnedEmailsFromSelectedEmail) ? (
              <Button
                type="button"
                appearance="primary"
                size="small"
                containsIcon
                onClick={unpinConversation}
              >
                <Icon icon="tack" />
              </Button>
            ) : (
              <Button
                type="button"
                appearance="outline"
                size="small"
                containsIcon
                onClick={pinConversation}
              >
                <Icon icon="tack" />
              </Button>
            )}
            {selected?.status?.isFixed ||
            (selectedEmail && fixedEmailsFromSelectedEmail) ? (
              <Button
                type="button"
                appearance="primary"
                size="small"
                containsIcon
                onClick={unfixConversation}
              >
                <Icon icon="check" />
              </Button>
            ) : (
              <Button
                type="button"
                appearance="outline"
                size="small"
                containsIcon
                onClick={fixConversation}
              >
                <Icon icon="check" />
              </Button>
            )}
          </div>
        </header>
        {isProcessing ? (
          <div className="flex justify-center">
            <Loader className="m-2 size-8" />
          </div>
        ) : null}
        <div className="conversations-container flex h-full flex-col overflow-y-scroll px-6 pb-2 pt-4">
          {renderMessages()}
        </div>
        <footer className="flex shrink-0 flex-col border-t border-gray-100">
          <FilesPreview
            files={files}
            canRemove={true}
            onRemoveFile={onRemoveFile}
            className="px-6 pt-6"
            sizeClassName="h-16"
          />
          <SimpleTextArea
            noStyle
            ref={input}
            id="textarea"
            value={message.value}
            onChange={onChangeTextarea}
            style={{ flexShrink: 1, margin: '24px' }}
            className="resize-none text-sm leading-5.5 text-gray-900"
          />
          <div className="flex flex-col justify-between border-t border-gray-100 px-6 py-4 xl:flex-row">
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-1">
                <button
                  type="button"
                  onClick={() => selectSmiley('🤓')}
                  className="emoji-fix"
                >
                  🤓
                </button>
                <button
                  type="button"
                  onClick={() => selectSmiley('😇')}
                  className="emoji-fix"
                >
                  😇
                </button>
                <button
                  type="button"
                  onClick={() => selectSmiley('😊')}
                  className="emoji-fix"
                >
                  😊
                </button>
                <Popover>
                  <PopoverTrigger
                    className="flex items-center text-xs text-green-900"
                    disabled={pushMessageLoading}
                  >
                    <Icon icon="face" />
                  </PopoverTrigger>
                  <PopoverContent
                    align="start"
                    className="w-auto overflow-scroll p-4"
                    sideOffset={12}
                  >
                    <EmojiPopoverContent selectSmiley={selectSmiley} />
                  </PopoverContent>
                </Popover>
              </div>
              {selected ? (
                <button
                  type="button"
                  onClick={handleFile}
                  className="text-green-900"
                >
                  <Icon icon="paperClip" />
                </button>
              ) : null}
              <Popover>
                <PopoverTrigger
                  className="flex items-center text-xs"
                  disabled={pushMessageLoading}
                >
                  Template
                  <Icon icon="arrowDown" className="ml-1" />
                </PopoverTrigger>
                <PopoverContent
                  align="start"
                  className="h-auto w-screen overflow-scroll p-4"
                  sideOffset={12}
                  style={{ maxWidth: '480px', height: '60vh' }}
                >
                  <TemplatePopoverContent changeText={changeText} />
                </PopoverContent>
              </Popover>
            </div>
            <div className="ml-auto flex items-center space-x-4 xl:ml-4">
              <div className="flex items-center whitespace-pre text-xs text-green-900">
                <span className="text-gray-900">
                  {t('conversation.answer_via')}{' '}
                </span>
                {selected ? (
                  <DeliverySelect
                    defaultValue={delivery}
                    options={[
                      {
                        value: 'messagerie',
                        label: (
                          <>
                            <Icon icon="smartphone" className="mr-2.5" />
                            <span>{t('conversation.answer_via_chat')}</span>
                          </>
                        ),
                        onClick: () => setDelivery('messagerie'),
                        disabled: false,
                      },
                      {
                        value: 'email',
                        label: (
                          <>
                            <Icon icon="mail" className="mr-2.5" />
                            <span>{t('conversation.answer_via_email')}</span>
                          </>
                        ),
                        onClick: () => setDelivery('email'),
                        disabled: true,
                      },
                    ]}
                    disabled={pushMessageLoading}
                  />
                ) : (
                  <span>email</span>
                )}
              </div>
              <div className="flex items-center space-x-2">
                <Button
                  type="button"
                  appearance="primary"
                  size="medium"
                  disabled={pushMessageLoading}
                  onClick={() => onInternalSubmit(undefined, undefined)}
                >
                  {displayFix
                    ? t('conversation.send_and_archive')
                    : t('conversation.send')}
                </Button>
                <SendSelect
                  options={[
                    {
                      label: (
                        <>
                          <Icon icon="tack" className="mr-2.5" />
                          <span>{t('conversation.send_and_pin')}</span>
                        </>
                      ),
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onClick: (e) => onInternalSubmit(e, MessageStatus.Pinned),
                    },
                    {
                      label: (
                        <>
                          <Icon icon="profileMultiple" className="mr-2.5" />
                          <span>{t('conversation.send_leave_open')}</span>
                        </>
                      ),
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onClick: (e) => onInternalSubmit(e, MessageStatus.Read),
                    },
                  ]}
                  disabled={pushMessageLoading}
                />
              </div>
            </div>
          </div>
        </footer>
        <input
          onChange={onUploadFile}
          multiple={true}
          type="file"
          accept="image/*,application/pdf"
          ref={inputFile}
        />
      </div>
      <Dialog.Root
        open={!!imageViewer}
        onOpenChange={() => setImageViewer(undefined)}
      >
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 z-40 bg-black/60" />
          <Dialog.Content className="fixed inset-0 z-40 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center">
              <ImageGallery
                slides={imageViewer?.slides}
                initialIndex={imageViewer?.initialIndex}
                onClose={() => setImageViewer(undefined)}
              />
            </div>
            <Dialog.DialogClose asChild>
              <Button
                type="button"
                containsIcon={true}
                onClick={() => setImageViewer(undefined)}
                className="absolute right-10 top-10"
              >
                <Icon icon="close" size="large" />
              </Button>
            </Dialog.DialogClose>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

ConversationThread.displayName = 'ConversationThread';
