import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import Alert from '@appchoose/alert';
import Badge from '@appchoose/badge';
import Button from '@appchoose/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Input from '@appchoose/input';
import Label from '@appchoose/label';
import Loader from '@appchoose/loader';
import { ModalFooter, ModalHeader, ModalTitle } from '@appchoose/modal';
import RadioGroup, { RadioGroupItem } from '@appchoose/radio-group';
import { toast } from '@appchoose/toast';

import { selectedOrdersState, selectedState } from '../../stores/selected';
import { useUser } from '../../stores/usercontext';
import {
  PostPurchaseReferralInputException,
  PostPurchaseReferralInputExceptionCode,
  PostPurchaseReferralPlan,
  StoreCurrency,
  useCreateCreditMutation,
  useCreatePostPurchaseReferralCreditMutation,
  useGetPostPurchaseReferralPlanLazyQuery,
} from '../../types/generated';
import { IUser } from '../../types/user';
import { formatPrice, transformPrice } from '../../utils/currency';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../tabs';

type AddCreditModalProps = {
  setIsOpen: (isOpen: boolean) => void;
  user: IUser;
};

type AddCreditForm = {
  credit: number;
  currency: StoreCurrency;
  description: string;
  referral_code: string;
};

export const AddCreditModal = ({ setIsOpen, user }: AddCreditModalProps) => {
  const { i18n, t } = useTranslation();

  const [selected, setSelected] = useRecoilState(selectedState);
  const selectedOrders = useRecoilValue(selectedOrdersState);

  const { user: userAuthenticated } = useUser();
  const [selectedTab, setSelectedTab] = useState<'amount' | 'referral'>(
    'amount'
  );

  const form = useForm<AddCreditForm>({
    mode: 'onTouched',
    defaultValues: {
      currency: StoreCurrency.Eur,
    },
  });

  const currencyOptions = [
    {
      name: 'Euro',
      value: StoreCurrency.Eur,
    },
    {
      name: 'Dollar',
      value: StoreCurrency.Usd,
    },
  ];

  const [
    getPostPurchaseReferralPlan,
    { data: parrainData, loading: parrainDataLoading, error: parrainDataError },
  ] = useGetPostPurchaseReferralPlanLazyQuery();
  const [
    createCredit,
    { loading: createCreditLoading, error: createCreditError },
  ] = useCreateCreditMutation();
  const [
    createPostPurchaseReferralCredit,
    {
      loading: createPostPurchaseReferralCreditLoading,
      error: createPostPurchaseReferralCreditError,
    },
  ] = useCreatePostPurchaseReferralCreditMutation();

  useEffect(() => {
    if (parrainData?.getPostPurchaseReferralPlan) {
      form.trigger('referral_code');
    }
  }, [parrainData]);

  useEffect(() => {
    if (parrainDataError) {
      toast.error(
        'Une erreur est survenue en essayant de récupérer les informations sur le code de parrainage'
      );
    }
  }, [parrainDataError]);

  useEffect(() => {
    if (createCreditError) {
      toast.error('Une erreur est survenue en ajoutant le crédit');
    }
  }, [createCreditError]);

  useEffect(() => {
    if (createPostPurchaseReferralCreditError) {
      toast.error('Une erreur est survenue en ajoutant le crédit');
    }
  }, [createPostPurchaseReferralCreditError]);

  const getTotalOrdersAmount = () => {
    return selectedOrders.reduce(
      (a, b) => a + b.totalPriceSold.valueWithVat,
      0
    );
  };

  const updateCredit = (amount: number, currency: StoreCurrency) => {
    if (!selected?.user) return;
    const credit = selected?.user?.credits.find(
      (credit) => credit.currency === currency
    );
    setSelected((prev) =>
      prev
        ? {
            ...prev,
            user: {
              ...prev.user,
              credits: [
                ...prev.user.credits.filter(
                  (credit) => credit.currency !== currency
                ),
                {
                  value: (credit?.value ?? 0) + amount * 100,
                  currency,
                },
              ],
            },
          }
        : undefined
    );
  };

  const onSubmit = async (data: AddCreditForm) => {
    try {
      if (selectedTab === 'amount') {
        await createCredit({
          variables: {
            data: {
              userKey: user.userKey || '',
              credit: {
                value: data.credit,
                currency: data.currency,
              },
              description: data.description,
              operator: userAuthenticated?.displayName ?? '',
            },
          },
        });
      } else {
        await createPostPurchaseReferralCredit({
          variables: {
            data: {
              userKey: user.userKey || '',
              code: data.referral_code.toUpperCase(),
            },
          },
        });
      }

      const referralPlan =
        parrainData?.getPostPurchaseReferralPlan as PostPurchaseReferralPlan;
      const credit =
        selectedTab === 'amount'
          ? data.credit
          : referralPlan?.me?.credit.value / 100;
      const currency =
        selectedTab === 'amount'
          ? data.currency
          : referralPlan?.me?.credit.currency;

      if (selected) {
        updateCredit(credit, currency);
      }

      const creditToDisplay = formatPrice(
        credit,
        i18n.language === 'fr' ? 'fr' : 'en',
        currency
      );

      toast.success(
        t(
          selectedTab === 'amount'
            ? t('credit.add_credit.success', {
                credit: creditToDisplay,
              })
            : t('credit.add_credit.success_referral', {
                credit: creditToDisplay,
                me: referralPlan?.me?.name,
                friend: referralPlan?.friend?.name,
              })
        )
      );

      setIsOpen(false);
    } catch {
      // Do nothing
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex h-full flex-col justify-between overflow-hidden"
      >
        <div className="flex flex-auto flex-col overflow-y-auto p-6 md:p-10">
          <ModalHeader>
            <ModalTitle>{t('credit.add_credit.title')}</ModalTitle>
          </ModalHeader>

          <Tabs
            value={selectedTab}
            onValueChange={(value) =>
              setSelectedTab(value as 'amount' | 'referral')
            }
          >
            <TabsList>
              <TabsTrigger value="amount">
                {t('credit.add_credit.tab_standard')}
              </TabsTrigger>
              <TabsTrigger value="referral">
                {t('credit.add_credit.tab_referral')}
              </TabsTrigger>
            </TabsList>

            <TabsContent value="amount">
              <div className="space-y-6">
                <FormField
                  control={form.control}
                  name="currency"
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <FormItem>
                      <FormLabel>
                        {t('credit.add_credit.fields.currency.label')}
                      </FormLabel>

                      <FormControl>
                        <RadioGroup
                          value={value}
                          onValueChange={onChange}
                          className="flex gap-6"
                        >
                          {currencyOptions.map((option) => (
                            <div
                              className="group flex items-center space-x-3"
                              key={option.value}
                            >
                              <RadioGroupItem
                                id={`currency-option-${option.value}`}
                                key={option.value}
                                value={option.value}
                              />
                              <Label
                                htmlFor={`currency-option-${option.value}`}
                                className="cursor-pointer text-sm text-gray-700 group-has-[.peer:disabled]:cursor-default group-has-[.peer:disabled]:text-gray-300 group-has-[.peer[data-state=checked]]:text-gray-900"
                              >
                                {option.name}
                              </Label>
                            </div>
                          ))}
                        </RadioGroup>
                      </FormControl>
                      <FormMessage match="required">
                        {t(
                          'credit.add_credit.fields.currency.validation_errors.required'
                        )}
                      </FormMessage>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="credit"
                  rules={{
                    required: true,
                    min: 0,
                    max: 100,
                  }}
                  disabled={selectedTab === 'referral'}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('credit.add_credit.fields.credit.label')}
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          placeholder={t(
                            'credit.add_credit.fields.credit.placeholder'
                          )}
                          {...field}
                          onChange={(event) => {
                            field.onChange(
                              !isNaN(event.target.valueAsNumber)
                                ? event.target.valueAsNumber
                                : null
                            );
                          }}
                        />
                      </FormControl>
                      <FormMessage match="required">
                        {t(
                          'credit.add_credit.fields.credit.validation_errors.required'
                        )}
                      </FormMessage>
                      <FormMessage match="min">
                        {t(
                          'credit.add_credit.fields.credit.validation_errors.min',
                          {
                            min: '0',
                          }
                        )}
                      </FormMessage>
                      <FormMessage match="max">
                        {t(
                          'credit.add_credit.fields.credit.validation_errors.max',
                          {
                            max: '100',
                          }
                        )}
                      </FormMessage>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="description"
                  rules={{
                    required: true,
                    maxLength: 10000,
                  }}
                  disabled={selectedTab === 'referral'}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('credit.add_credit.fields.description.label')}
                      </FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t(
                            'credit.add_credit.fields.description.placeholder'
                          )}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage match="required">
                        {t(
                          'credit.add_credit.fields.description.validation_errors.required'
                        )}
                      </FormMessage>
                      <FormMessage match="maxLength">
                        {t(
                          'credit.add_credit.fields.description.validation_errors.maxLength',
                          {
                            maxLength: '10000',
                          }
                        )}
                      </FormMessage>
                    </FormItem>
                  )}
                />
              </div>
            </TabsContent>
            <TabsContent value="referral">
              {user.phoneNumber ? (
                <>
                  {getTotalOrdersAmount() < 2000 ? (
                    <Alert
                      title="Montant minimum requis non atteint"
                      appearance="error"
                      icon="alert"
                      className="mb-6"
                    >
                      {t('credit.add_credit.minimum_amount_not_reached', {
                        amount: transformPrice(
                          2000,
                          i18n.language === 'fr' ? 'fr' : 'en',
                          StoreCurrency.Eur
                        ),
                      })}
                    </Alert>
                  ) : null}
                  <FormField
                    control={form.control}
                    name="referral_code"
                    rules={{
                      required: true,
                      maxLength: 100,
                      validate: {
                        notExist: () =>
                          (
                            parrainData?.getPostPurchaseReferralPlan as PostPurchaseReferralInputException
                          )?.exceptionCode !==
                          PostPurchaseReferralInputExceptionCode.CodeNotFound,
                        userAlreadyBenefitedReferral: () =>
                          (
                            parrainData?.getPostPurchaseReferralPlan as PostPurchaseReferralInputException
                          )?.exceptionCode !==
                          PostPurchaseReferralInputExceptionCode.UserAlreadyBenefitedReferral,
                      },
                    }}
                    disabled={selectedTab === 'amount'}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          {t('credit.add_credit.fields.referral_code.label')}
                        </FormLabel>
                        <FormControl>
                          <Input
                            placeholder={t(
                              'credit.add_credit.fields.referral_code.placeholder'
                            )}
                            {...field}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              field.onChange(event);
                              getPostPurchaseReferralPlan({
                                variables: {
                                  data: {
                                    code: event.currentTarget.value,
                                    userKey: user.userKey,
                                  },
                                },
                              });
                            }}
                          />
                        </FormControl>
                        <FormMessage match="required">
                          {t(
                            'credit.add_credit.fields.referral_code.validation_errors.required'
                          )}
                        </FormMessage>
                        <FormMessage match="maxLength">
                          {t(
                            'credit.add_credit.fields.referral_code.validation_errors.maxLength',
                            {
                              maxLength: '100',
                            }
                          )}
                        </FormMessage>
                        <FormMessage match="notExist">
                          {t(
                            'credit.add_credit.fields.referral_code.validation_errors.notExist'
                          )}
                        </FormMessage>
                        <FormMessage match="userAlreadyBenefitedReferral">
                          {t(
                            'credit.add_credit.fields.referral_code.validation_errors.userAlreadyBenefitedReferral'
                          )}
                        </FormMessage>
                      </FormItem>
                    )}
                  />
                  {parrainDataLoading ? (
                    <Loader className="mt-2 size-6" />
                  ) : null}
                  {parrainData?.getPostPurchaseReferralPlan &&
                    !(
                      'exceptionCode' in parrainData.getPostPurchaseReferralPlan
                    ) && (
                      <div className="mt-2 space-y-1">
                        <div className="text-sm font-bold text-gray-900">
                          {t('credit.add_credit.amount_to_credit')}
                        </div>
                        <p className="text-sm text-gray-500">
                          +
                          <b className="text-gray-900">
                            {transformPrice(
                              parrainData?.getPostPurchaseReferralPlan.me.credit
                                .value,
                              i18n.language === 'fr' ? 'fr' : 'en',
                              parrainData?.getPostPurchaseReferralPlan.me.credit
                                .currency
                            )}
                          </b>{' '}
                          {parrainData?.getPostPurchaseReferralPlan?.me?.name}{' '}
                          <Badge>
                            {t('credit.add_credit.concerned_customer')}
                          </Badge>
                        </p>
                        <p className="text-sm text-gray-500">
                          +
                          <b className="text-gray-900">
                            {transformPrice(
                              parrainData?.getPostPurchaseReferralPlan.friend
                                .credit.value,
                              i18n.language === 'fr' ? 'fr' : 'en',
                              parrainData?.getPostPurchaseReferralPlan.friend
                                .credit.currency
                            )}
                          </b>{' '}
                          {parrainData?.getPostPurchaseReferralPlan.friend.name}
                        </p>
                      </div>
                    )}
                </>
              ) : (
                <div>
                  <p className="text-sm text-red-600">
                    {t(
                      'credit.add_credit.customer_must_have_valid_phone_number'
                    )}
                  </p>
                </div>
              )}
            </TabsContent>
          </Tabs>
        </div>

        <ModalFooter>
          <Button
            appearance="primary"
            type="submit"
            disabled={
              createCreditLoading || createPostPurchaseReferralCreditLoading
            }
          >
            {t('credit.add_credit.add')}
          </Button>
        </ModalFooter>
      </form>
    </Form>
  );
};
